"use client"; // ✅ Ensure this runs only in the browser

import dynamic from "next/dynamic";

const Base64ConverterClient = dynamic(() => import("./Base64ConverterClient")); // ✅ Remove `ssr: false`

interface Base64ConverterWrapperClientProps {
  mode?: "encode" | "decode";
  translations: Record<string, string>;
}

export default function Base64ConverterWrapperClient({
  mode,
  translations,
}: Base64ConverterWrapperClientProps) {
  return <Base64ConverterClient mode={mode} translations={translations} />;
}
