"use client";

import { useLocale } from "next-intl";
import { useRouter, usePathname } from "next/navigation";
import { useState, useMemo } from "react";
import { routing } from "@i18n/routing"; // Import the routing configuration

export default function LanguageSwitcher() {
  const locale = useLocale(); // Get current locale
  const router = useRouter();
  const _pathname = usePathname(); // Get current URL path
  const [selectedLocale, setSelectedLocale] = useState(locale);

  // Memoized flag emojis
  const flagEmojis = useMemo(
    () => ({
      en: "🇬🇧",
      zh: "🇨🇳",
      es: "🇪🇸",
      ar: "🇸🇦",
      pt: "🇵🇹",
      fr: "🇫🇷",
      ru: "🇷🇺",
      de: "🇩🇪",
      ja: "🇯🇵",
      hi: "🇮🇳",
      bn: "🇧🇩",
      ur: "🇵🇰",
      it: "🇮🇹",
      tr: "🇹🇷",
      vi: "🇻🇳",
      ko: "🇰🇷",
      pl: "🇵🇱",
      fa: "🇮🇷",
      nl: "🇳🇱",
      th: "🇹🇭",
      uk: "🇺🇦",
      id: "🇮🇩",
      ro: "🇷🇴",
      el: "🇬🇷",
      hu: "🇭🇺",
      sv: "🇸🇪",
      cs: "🇨🇿",
      da: "🇩🇰",
      fi: "🇫🇮",
      he: "🇮🇱",
      no: "🇳🇴",
      sk: "🇸🇰",
      bg: "🇧🇬",
      sr: "🇷🇸",
      hr: "🇭🇷",
      lt: "🇱🇹",
      sl: "🇸🇮",
      ms: "🇲🇾",
      et: "🇪🇪",
      lv: "🇱🇻",
      tl: "🇵🇭",
      mt: "🇲🇹",
      is: "🇮🇸",
      ga: "🇮🇪",
      sw: "🇹🇿",
      af: "🇿🇦",
      eu: "🇪🇺",
      gl: "🇪🇸",
      hy: "🇦🇲",
      mk: "🇲🇰",
    }),
    [],
  );

  // Memoized language labels
  const languageLabels = useMemo(
    () => ({
      en: "English",
      zh: "中文 (Chinese)",
      es: "Español",
      ar: "العربية (Arabic)",
      pt: "Português",
      fr: "Français",
      ru: "Русский (Russian)",
      de: "Deutsch",
      ja: "日本語 (Japanese)",
      hi: "हिन्दी (Hindi)",
      bn: "বাংলা (Bengali)",
      ur: "اردو (Urdu)",
      it: "Italiano",
      tr: "Türkçe (Turkish)",
      vi: "Tiếng Việt (Vietnamese)",
      ko: "한국어 (Korean)",
      pl: "Polski",
      fa: "فارسی (Persian)",
      nl: "Nederlands (Dutch)",
      th: "ไทย (Thai)",
      uk: "Українська (Ukrainian)",
      id: "Bahasa Indonesia",
      ro: "Română (Romanian)",
      el: "Ελληνικά (Greek)",
      hu: "Magyar (Hungarian)",
      sv: "Svenska (Swedish)",
      cs: "Čeština (Czech)",
      da: "Dansk (Danish)",
      fi: "Suomi (Finnish)",
      he: "עברית (Hebrew)",
      no: "Norsk (Norwegian)",
      sk: "Slovenčina (Slovak)",
      bg: "Български (Bulgarian)",
      sr: "Српски (Serbian)",
      hr: "Hrvatski (Croatian)",
      lt: "Lietuvių (Lithuanian)",
      sl: "Slovenščina (Slovenian)",
      ms: "Bahasa Melayu (Malay)",
      et: "Eesti (Estonian)",
      lv: "Latviešu (Latvian)",
      tl: "Filipino",
      mt: "Malti (Maltese)",
      is: "Íslenska (Icelandic)",
      ga: "Gaeilge (Irish)",
      sw: "Kiswahili (Swahili)",
      af: "Afrikaans",
      eu: "Euskara (Basque)",
      gl: "Galego (Galician)",
      hy: "Հայերեն (Armenian)",
      mk: "Македонски (Macedonian)",
    }),
    [],
  );

  const handleLocaleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLocale = event.target.value;
    setSelectedLocale(newLocale);

    // Replace the old locale in the URL with the new one
    const newPath = _pathname.replace(/^\/[a-z]{2}/, `/${newLocale}`);

    // Redirect to the new URL
    router.push(newPath);
  };

  return (
    <div className="language-switcher-container">
      <select
        id="language-selector"
        value={selectedLocale}
        onChange={handleLocaleChange}
        className="language-switcher"
        aria-label="Select Language"
      >
        {routing.locales.map((code) => (
          <option key={code} value={code}>
            {flagEmojis[code] || "🏳"}{" "}
            {languageLabels[code] || code.toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
}
