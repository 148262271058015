import { defineRouting } from "next-intl/routing";
import { createNavigation } from "next-intl/navigation";

export const supportedLocales = [
  "en",
  "es",
  "fr",
  "zh",
  "ar",
  "pt",
  "ru",
  "de",
  "ja",
  "hi",
  "bn",
  "id",
  "tr",
  "vi",
  "ko",
  "pl",
  "uk",
  "fa",
] as const;

export type SupportedLocale = (typeof supportedLocales)[number];

// ✅ Define routing with proper configuration
const routing = defineRouting({
  defaultLocale: "en",
  locales: supportedLocales,
  // Optional: Add a prefix for locale-specific routes (e.g., /en/about)
  localePrefix: "always", // or "as-needed" or "never"
});

// ✅ Export routing and navigation utilities
export { routing };

export const { Link, redirect, usePathname, useRouter, getPathname } =
  createNavigation(routing);
