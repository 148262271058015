import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/encode64/frontend/app/components/Base64ConverterWrapperClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/encode64/frontend/app/components/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/encode64/frontend/app/styles/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/encode64/frontend/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/encode64/frontend/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/var/www/encode64/frontend/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/encode64/frontend/node_modules/next/dist/client/image-component.js");
